import messenger from '@checkout-ui/shared/cross-document-messenger/messenger';
import { TOPICS } from '@checkout-ui/shared/cross-document-messenger/types';
import { DirectHppModalSession } from '@checkout-ui/shared-context-session';
import { isHppEcommerceSession } from '@checkout-ui/shared-domain-entities';
import { logger } from '@checkout-ui/shared-logger';

import {
  attachModalHandlers,
  iFrameError,
  MODAL_SCENARIOS,
  ModalFrame,
  openModalFrame,
} from '../modalFrame';
import sdkState from '../state';
import { AuthorizeData, AuthorizeErrors, AuthorizePromise } from './types';
import { validateParameters } from './validation';

const handleAuthorizeError = (
  frame: ModalFrame,
  error?: AuthorizeErrors
): AuthorizePromise => {
  const sendErrorData = () => {
    logger.log('SDK handleAuthorizeError called with:', error);

    // Forward the session context from the sdk.
    messenger.publish(TOPICS.show_error, error);
  };

  return new Promise((resolve) => {
    attachModalHandlers(
      frame,
      {
        onOpen: sendErrorData,
        onClose: (result) => resolve([undefined, result]),
      },
      MODAL_SCENARIOS.error
    );
  });
};

const handleAuthorizeReturn = (
  frame: ModalFrame,
  data?: AuthorizeData
): AuthorizePromise => {
  const sendAuthorizeData = () => {
    // Forward the session context from the sdk.
    const { sessionId, purchaseCountry, locale, integrationType } =
      sdkState.getState();

    const isHPPSession = (
      session_context: AuthorizeData['session_context']
    ): session_context is DirectHppModalSession => {
      // cannot use discriminated type checks here as integration type is set on the FE and does not come from BE
      return !!(session_context as DirectHppModalSession)?.channel;
    };

    const isAutoconfirm = (d: AuthorizeData | undefined): boolean => {
      if (!d || !d.session_context) {
        return false;
      }

      if (isHppEcommerceSession(d.session_context)) {
        return d.session_context.autoconfirm || false;
      }

      return false;
    };

    const session_context = {
      token: sessionId,
      country: purchaseCountry,
      locale,
      currency: data?.session_context?.currency || 'EUR',
      integrationType,
      ...(isHPPSession(data?.session_context)
        ? {
            channel: data?.session_context?.channel,
            confirmed: data?.session_context?.confirmed,
            autoconfirm: isAutoconfirm(data),
          }
        : null),
    } as const;

    const authorizeDataWithSessionContext: AuthorizeData = {
      ...data,
      session_context,
    };

    logger.log(
      'SDK sendAuthorizeData called with:',
      authorizeDataWithSessionContext
    );

    // SDK sending to modal
    messenger.publish(TOPICS.authorize, authorizeDataWithSessionContext);
  };

  return new Promise((resolve) => {
    attachModalHandlers(
      frame,
      {
        onOpen: sendAuthorizeData,
        onClose: (result) => {
          resolve([undefined, result]);
        },
        onResult: (result) => {
          resolve([undefined, result]);
        },
        onError: (error) => {
          resolve([error, undefined]);
        },
      },
      MODAL_SCENARIOS.authorize
    );
  });
};

export function authorize(data: AuthorizeData): AuthorizePromise {
  const { uiConfig } = sdkState.getState();
  const showErrorModal = uiConfig?.showErrorModal;

  const validationError = validateParameters(data);

  if (!showErrorModal && validationError !== undefined) {
    return Promise.resolve([validationError, undefined]);
  }

  const modalFrame = openModalFrame(uiConfig);
  if (!modalFrame) {
    return Promise.resolve([iFrameError, undefined]);
  }

  if (validationError !== undefined) {
    logger.error('validationError in SDK.Authorize', validationError);
    return handleAuthorizeError(modalFrame, validationError);
  }

  return handleAuthorizeReturn(modalFrame, data);
}
