import { logger } from '@checkout-ui/shared-logger';
import { thirdPartyErrorFilterIntegration } from '@sentry/browser';

import { BillieCheckoutWidget } from '../../types';
import { SENTRY_APPLICATION_KEY } from '../constants';
import { authorize } from './lib/authorize';
import { configurationLoader, getConfigUrl } from './lib/config';
import { initialize } from './lib/initialize';
import { mount } from './lib/mount';
import { on } from './lib/on';
import { fingerprintjs, segment, sentry } from './lib/services';
import sdkState from './lib/state';
import { withWaitForConfig } from './lib/withWaitForConfig';

logger.setConfig({ name: 'Direct SDK', color: 'darkRed' });

declare global {
  interface Window {
    BillieSDK: {
      authorize: typeof authorize;
      initialize: typeof initialize;
      on: typeof on;
    };
    BillieCheckoutWidget: BillieCheckoutWidget;
    __BILLIE_CHECKOUT_CONFIG__: unknown;
  }
}

window.BillieSDK = {
  authorize: withWaitForConfig(authorize),
  initialize,
  on,
};

window.BillieCheckoutWidget = {
  mount: withWaitForConfig(mount),
};

window.__BILLIE_CHECKOUT_CONFIG__ = {};

if (
  document.currentScript &&
  document.currentScript instanceof HTMLScriptElement
) {
  const currentScriptSrc = document.currentScript.src;

  configurationLoader.updateOptions({
    src: getConfigUrl(document.currentScript.src),
  });

  configurationLoader.fetchConfig();

  configurationLoader.on(
    configurationLoader.events.Finished,
    async ({ config }) => {
      segment.init(config.SEGMENT_WRITE_KEY, logger, {
        disable: !config.SENTRY_ENABLED,
      });

      const documentUrl = new URL(document.URL);

      const merchantHost = documentUrl.host;

      sdkState.setState({
        merchantHost,
      });

      segment.setMetaData({
        sdkVersion: 'v1',
        merchantHost,
        sdkSource: currentScriptSrc,
      });

      segment.trackEvent('Direct SDK loaded');

      await sentry.init({
        enabled: config.SENTRY_ENABLED,
        debug: config.SENTRY_DEBUG,
        dsn: config.SENTRY_DSN,
        environment: config.SENTRY_ENVIRONMENT,
        integrations: [
          thirdPartyErrorFilterIntegration({
            filterKeys: [SENTRY_APPLICATION_KEY],
            behaviour: 'drop-error-if-contains-third-party-frames',
          }),
        ],
        logger,
      });

      sentry.setContext('integration', {
        sdk_version: 'v1',
        merchant_host: merchantHost,
        sdk_source: currentScriptSrc,
      });

      await fingerprintjs.init({
        apiKey: config.FINGERPRINTJS_API_KEY,
        enabled: config.FINGERPRINTJS_ENABLED,
        logger,
        sentry,
      });
    }
  );
}
