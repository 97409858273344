import { ConfigurationStatus } from '@checkout-ui/shared-context-configuration/types';

import { configurationLoader } from './config';
import { DelayableFunction } from './types';

// FIXME: the second argument could be a rejection payload,
//  as we potentially don't want to reject duplicate calls
//  with just `undefined`, but a meaningful message instead
export const withWaitForConfig = <F extends DelayableFunction>(f: F) => {
  const queued: {
    args: null | Parameters<F>;
    callback: null | (() => void);
    resolve: null | ((status: ConfigurationStatus) => void);
    reject: null | (() => void);
  } = {
    args: null,
    callback: null,
    resolve: null,
    reject: null,
  };

  return async (...args: Parameters<F>) => {
    if (
      [ConfigurationStatus.Ready, ConfigurationStatus.Error].includes(
        configurationLoader.status
      )
    ) {
      return f(...args);
    }

    if (queued.reject) {
      queued.reject();
    }

    const configPromise = new Promise((resolve, reject) => {
      queued.args = args;
      queued.reject = reject;
      queued.resolve = resolve;

      if (!queued.callback) {
        queued.callback = () => {
          configurationLoader.off(
            configurationLoader.events.Finished,
            queued.callback!
          );
          queued.resolve!(configurationLoader.status);
        };

        configurationLoader.on(
          configurationLoader.events.Finished,
          queued.callback
        );
      }
    });

    return configPromise.then(() => f(...queued.args!));
  };
};
