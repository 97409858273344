/* eslint-disable @typescript-eslint/no-explicit-any */
import messenger from '@checkout-ui/shared/cross-document-messenger/messenger';
import { TOPICS } from '@checkout-ui/shared/cross-document-messenger/types';
import { AuthorizationModalData } from '@checkout-ui/shared/data-transformers';
import { logger } from '@checkout-ui/shared-logger';

import { configurationLoader } from '../config';
import sdkState, { type SDKState } from '../state';
import type { IFrameError } from '../types';
import { UiConfig } from '../types';
import { AuthorizeResultError, ErrorType } from '../utils/errors';
import {
  FrameHandlers,
  MODAL_SCENARIOS,
  ModalFrame,
  ModalSubscribeEventHandlers,
} from './types';

export const BODY_CLASS_NAME = 'direct-frame-loaded';
export const STYLES_ID = 'direct-sdk-styles';
export const FRAME_ID = 'billie-direct-sdk-iframe';

const getFrameBodyStyles = () => document.getElementById(STYLES_ID);

const addStyleToDocument = () => {
  const styleExist = !!getFrameBodyStyles();

  if (styleExist) return;

  const css = `
    .${BODY_CLASS_NAME} {
      overflow: hidden
    }
  `;

  const head = document.head;
  const style = document.createElement('style');
  head.appendChild(style);

  style.id = STYLES_ID;
  style.appendChild(document.createTextNode(css));
  document.body.classList.add(BODY_CLASS_NAME);
};

const removeStyleFromDocument = () => {
  getFrameBodyStyles()?.remove();
  document.body.classList.remove(BODY_CLASS_NAME);
};

const getQueryString = ({ purchaseCountry, locale, uiConfig }: SDKState) => {
  const closeableQuery = uiConfig?.closeable
    ? `&closeable=${uiConfig?.closeable}`
    : '';

  const query = `?country=${purchaseCountry}&locale=${locale}${closeableQuery}`;

  return query;
};

const getFrameSrc = () => {
  const src = `${configurationLoader.config.MODAL_SRC}${getQueryString(
    sdkState.getState()
  )}`;

  return src;
};

export const iFrameError: IFrameError = {
  type: ErrorType.IFrameError,
  errors: [{ field: 'iframe', message: "couldn't open widget frame" }],
};

const setModalFrameStyle = (frame: ModalFrame, isFullScreen: boolean) => {
  const styles: Record<string, string> = {
    ...(isFullScreen ? { position: 'fixed', left: '0px', top: '0px' } : null),

    width: '100%',
    height: '100%',
    'z-index': '999999',
    border: 'none',
  };

  const priorities: Record<string, string> = {
    'z-index': 'important',
  };

  for (const styleProps in styles) {
    frame.style.setProperty(
      styleProps,
      styles[styleProps] as string,
      priorities[styleProps]
    );
  }
};

const setModalFrameProps = (frame: ModalFrame, isFullScreen: boolean) => {
  frame.src = getFrameSrc();
  frame.id = FRAME_ID;

  setModalFrameStyle(frame, isFullScreen);
};

const createModalFrame = (isFullScreen: boolean) => {
  const iframe = document.createElement('iframe');

  setModalFrameProps(iframe, isFullScreen);

  return iframe;
};

export const openModalFrame = (uiConfig?: UiConfig): ModalFrame | undefined => {
  const frameExist = document.getElementById(FRAME_ID);

  const customQuerySelector =
    uiConfig?.container && document.querySelector(uiConfig?.container);
  const fallBackContainer = document.body;

  const wrapper = customQuerySelector ? customQuerySelector : fallBackContainer;
  let appendFrameToContainer;

  const isFullScreen = !customQuerySelector;

  if (!frameExist) {
    if (wrapper) {
      appendFrameToContainer = wrapper.appendChild(
        createModalFrame(isFullScreen)
      );
    }

    if (appendFrameToContainer && isFullScreen) {
      addStyleToDocument();
    }
    return appendFrameToContainer;
  }

  if (frameExist instanceof HTMLIFrameElement) {
    return frameExist;
  }

  return;
};

export const unmountModalFrame = (frame: ModalFrame) => {
  frame.remove();
  removeStyleFromDocument();
};

const subscribeToModalEvents = (
  frame: ModalFrame,
  { onClose, onResult, onError }: ModalSubscribeEventHandlers
) => {
  if (onClose) {
    messenger.subscribe(TOPICS.close, (result) => {
      onClose(result);
      unmountModalFrame(frame);
    });
  }

  if (onResult) {
    messenger.subscribe(TOPICS.result, (result: AuthorizationModalData) => {
      onResult(result);
      unmountModalFrame(frame);
    });
  }

  if (onError) {
    messenger.subscribe(TOPICS.error, (result) => {
      onError(result as AuthorizeResultError);
      unmountModalFrame(frame);
    });
  }
};

const flowTopics = {
  [MODAL_SCENARIOS.error]: [TOPICS.intent, TOPICS.show_error],
  [MODAL_SCENARIOS.authorize]: [TOPICS.intent, TOPICS.authorize],
};

export const attachModalHandlers = (
  frame: ModalFrame,
  { onOpen, ...modalEventsHandlers }: FrameHandlers,
  flow: MODAL_SCENARIOS
) => {
  const onModalFrameLoaded = () => {
    const topics = flowTopics[flow];

    const isReady = messenger.init({
      persistedTopics: topics,
      origin: '*',
      targetFrames: [frame],
    });

    if (!isReady) {
      logger.error('Modal messenger is not ready');
      return;
    }

    subscribeToModalEvents(frame, modalEventsHandlers);

    onOpen();
  };

  frame.addEventListener('load', onModalFrameLoaded);
};
