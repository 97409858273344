import { Session } from '@checkout-ui/shared-domain-entities';

import { RecursivePartial } from './helpers';

export const isKlarnaSessionWithIntent = (
  sessionContext: RecursivePartial<Session> | undefined,
  intent: 'buy' | 'tokenize' | 'buy_and_tokenize'
) =>
  sessionContext &&
  sessionContext.integrationType &&
  sessionContext.integrationType === 'klarna' &&
  sessionContext.intent &&
  sessionContext.intent === intent;

export const checkIsTokenizationFlow = (
  sessionContext: RecursivePartial<Session> | undefined
) =>
  sessionContext &&
  sessionContext.integrationType &&
  sessionContext.integrationType === 'klarna' &&
  sessionContext.intent &&
  ['tokenize', 'buy_and_tokenize'].includes(sessionContext.intent);
