import { RecursivePartial } from '@checkout-ui/shared/utils';
import {
  DirectHppModalSession,
  DirectModalSession,
} from '@checkout-ui/shared-context-session';
import {
  AuthorizationResults,
  DirectModalDebtorCompany,
  DirectModalDebtorPerson,
  HppModalDebtorCompany,
  LocaleType,
  PaymentDetails,
  PurchaseDetails,
} from '@checkout-ui/shared-domain-entities';
import { logger } from '@checkout-ui/shared-logger';

import {
  AuthorizationModalData,
  DirectAuthResponseEvaluation,
  DirectState,
} from '../authorizationDataTypes';

export const transformAmount = (purchase_details: PurchaseDetails) => {
  if (
    purchase_details.amount &&
    typeof purchase_details.amount.total !== 'undefined' &&
    typeof purchase_details.amount.net !== 'undefined' &&
    typeof purchase_details.amount.tax !== 'undefined'
  ) {
    //?INFO: The Modal internally stores money in non-negative minor units eg: cents
    // but our public APIs including Authorize endpoint unfortunately expect major unit eg: Euro
    // https://ozean12.atlassian.net/browse/CHECK-530

    return {
      net: purchase_details.amount.net / 100,
      gross: purchase_details.amount.total / 100,
      tax: purchase_details.amount.tax / 100,
    };
  }

  throw new Error('amount does not exist');
};

export const transformDuration = (payment_details: PaymentDetails) => {
  if (payment_details?.payment_deadline) {
    return payment_details.payment_deadline;
  }
  throw new Error('duration does not exist');
};

export const transformDeliveryAddress = (purchase_details: PurchaseDetails) => {
  if (!purchase_details?.shipping_address) {
    logger.log('shipping address does not exist');
    return;
  }

  const { city, country, postal_code, street, address_addition, number } =
    purchase_details.shipping_address;

  if (!city || !country || !postal_code || !street) {
    return;
  }

  return {
    street: street,
    city: city,
    postal_code: postal_code,
    country: country,
    ...(number && { house_number: number }),
    ...(address_addition && { address_addition }),
  };
};

export const transformBillingAddress = (purchase_details: PurchaseDetails) => {
  if (!purchase_details?.billing_address) {
    logger.log('billing address does not exist');
    return;
  }

  const { address_addition, number, street, postal_code, city, country } =
    purchase_details.billing_address;

  if (!city || !country || !postal_code || !street) {
    return;
  }

  return {
    street,
    city,
    postal_code,
    country,
    ...(number && { house_number: number }),
    ...(address_addition && { addition: address_addition }),
  };
};

export const transformDebtorCompany = (
  debtor_company:
    | RecursivePartial<DirectModalDebtorCompany>
    | RecursivePartial<HppModalDebtorCompany>
) => {
  if (!debtor_company) {
    throw new Error('debtor company does not exist');
  }
  const defaultLimitedCompanyLegalForm = '10001';

  return {
    legal_form: debtor_company?.legal_form || defaultLimitedCompanyLegalForm,
    name: debtor_company?.name || '',
    merchant_customer_id: debtor_company?.merchant_customer_id || '',
    registration_number: debtor_company?.organization_registration_id || '',
    address_house_number: debtor_company?.address?.number,
    address_street: debtor_company?.address?.street || '',
    address_city: debtor_company?.address?.city || '',
    address_postal_code: debtor_company?.address?.postal_code || '',
    address_country: debtor_company?.address?.country || '',
    address_addition: debtor_company?.address?.address_addition || '',
  };
};

export const transformDebtorPerson = (
  debtorPerson: DirectModalDebtorPerson | undefined,
  session_locale?: LocaleType
) => {
  if (!debtorPerson) {
    return {};
  }

  return {
    salutation: debtorPerson?.salutation,
    first_name: debtorPerson?.given_name,
    last_name: debtorPerson?.family_name,
    phone_number: debtorPerson?.phone,
    email: debtorPerson?.email,
    locale: debtorPerson?.locale || session_locale || 'en-US',
  };
};

export const transformLineItems = (purchase_details: PurchaseDetails) => {
  if (purchase_details && purchase_details.line_items) {
    return purchase_details.line_items;
  }

  return [];
};

const getStateFromResult = (result?: AuthorizationResults): DirectState => {
  const fallbackState = 'declined';
  const map: Record<AuthorizationResults, DirectState> = {
    [AuthorizationResults.ACCEPTED]: 'authorized',
    [AuthorizationResults.REJECTED]: 'declined',
    [AuthorizationResults.USER_ACTION_REQUIRED]: 'declined',
  };

  if (!result || !map[result]) {
    return fallbackState;
  }

  return map[result];
};

export const transformAuthorizationEvaluation = (
  authorization_evaluation: AuthorizationModalData['authorization_evaluation']
): DirectAuthResponseEvaluation | null => {
  if (!authorization_evaluation) return null;

  return {
    state: getStateFromResult(authorization_evaluation.result),
    decline_reason: authorization_evaluation.decline_reason,
  };
};

export const transformSession = (
  session_context: RecursivePartial<DirectModalSession | DirectHppModalSession>
) => {
  //FIXME: check why condition here?
  if (session_context?.integrationType === 'hpp') {
    return {
      session_context: {
        channel: session_context.channel || 'default_strategy',
        confirmed: session_context?.confirmed,
        locale: session_context?.locale,
        autoconfirm: session_context?.autoconfirm || false,
        currency: session_context?.currency,
      },
    };
  }
  return {};
};
