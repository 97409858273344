import { createConfigurationLoader } from '@checkout-ui/shared-context-configuration/createConfigurationLoader';
import {
  bool,
  oneof,
  str,
} from '@checkout-ui/shared-context-configuration/parsers';
import { SentryServiceEnvironment } from '@checkout-ui/shared-services';

export const getConfigUrl = (scriptSrc: string): string => {
  const url = new URL(scriptSrc);

  const prefix = url.pathname.split('/').slice(0, -1);
  const pathname = [...prefix, 'runtime', 'config.json'].join('/');

  return url.origin + pathname;
};

export const configurationLoader = createConfigurationLoader(
  {
    DEPLOYMENT_ENV: str('production'),
    MODAL_SRC: str('https://direct.billie.io/modal'),
    SEGMENT_ENABLED: bool(true),
    SEGMENT_WRITE_KEY: str('Y7OlGyFDm7qr1HyutHYiph1vus5vJGUY'),
    SENTRY_ENABLED: bool(true),
    SENTRY_DEBUG: bool(false),
    SENTRY_DSN: str(
      'https://d48151096ee384e64773ea08aac0b683@o60503.ingest.us.sentry.io/4507884032950272'
    ),
    SENTRY_ENVIRONMENT: oneof(
      SentryServiceEnvironment.Production,
      Object.values(SentryServiceEnvironment)
    ),
    FINGERPRINTJS_ENABLED: bool(true),
    FINGERPRINTJS_API_KEY: str('oaYF6bXbqsjb5tKpjoH4'),
  },
  {
    src: 'https://checkout.billie.io/placeholder.json',
  }
);
