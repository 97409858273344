import { logger } from '@checkout-ui/shared-logger';
import { Failure } from 'superstruct';

import { ErrorField, ErrorType, ValidationError } from '../utils/errors';
import { joinObjectPath } from '../utils/joinObjectPath';
import { publicApiValidationSchema, validationSchema } from './fixtures';
import { AuthorizeData } from './types';

const transformValidationError = ({
  key,
  type,
  path,
  message,
}: Failure): ErrorField => {
  const isUnknownFieldError = type === 'never';
  const isNotAFunction = key === 'callback';
  if (isUnknownFieldError) {
    return {
      field: key,
      message: 'invalid_field',
    };
  }
  if (isNotAFunction) {
    return {
      field: key,
      message: 'callback parameter must be a function',
    };
  }

  return {
    field: joinObjectPath(path),
    message,
  };
};

export const validateParameters = (
  { options, ...data }: AuthorizeData | undefined = { options: {} }
): ValidationError | undefined => {
  if (data) {
    const [error] = options?.usePublicApiValidationSchema
      ? publicApiValidationSchema.validate({ billie_order_data: data })
      : validationSchema.validate({ data: { ...data } });
    if (!error) return;
    logger.error('validationSchema', error);

    const validationErrors: ValidationError = {
      type: ErrorType.ValidationError,
      errors: error.failures().map(transformValidationError),
    };
    return validationErrors;
  }
  return undefined;
};
